// @ts-nocheck
import {ref, Ref, watch} from "vue";
import {AjaxHelper} from "ajax_helper";
import {AjaxHelperResponse} from "ajax_helper/src/AjaxHelperResponse";
import moment from 'moment';
import {DsUser} from "~/helper/User/DsUser";
import Toast from "~/helper/Toast";

moment.locale('fr', {
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm'
    },
    calendar: {
        sameDay: '[Aujourd’hui à] LT',
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // Used to determine first week of the year.
    }
});
export const open_ticket: Ref<Boolean> = ref(false);
export const dashboard: Ref<Array> = ref([]);
export const ticket_select: Ref<number | null> = ref(null);
export const tickets: Ref<Array> = ref([]);
export const paginate: Ref<Array> = ref([]);
export const elements: Ref<Array> = ref([]);
export const last_discussions: Ref<Array> = ref([]);
export const chat_form: Ref<Array<any>> = ref({message: ''});
export const ticket_data: Ref<Object> = ref({
    id: 0,
    project_id: 0,
    project_color: '',
    status_id: 0,
    status_color: '',
    chat: [],
    other_tickets: [],
    product: [],
    customer: [],
    order: []
});
export const show_discussion_form: Ref<Boolean> = ref(false);
export const show_trashed: Ref<Boolean> = ref(false);
export const actual_url: Ref<string> = ref(null);
export const discussion_form: Ref<Array<any>> = ref({
    id: '',
    status: 'draft',
    message: '',
    respond_to: ''
});
export const current_page: Ref<Number> = ref(1);
export const nb_page: Ref<Number> = ref(null);

export const show_loading_animation: Ref<string> = ref('');
export const ajax_processing: Ref<Array> = ref([]);

export function useOnMountTicket() : { type_ticket: Ref<string> } {
    const type_ticket: Ref<string> = ref('public');

    if (window.location.href.includes('/internal')) {
        type_ticket.value = 'internal';
    } else if (window.location.href.includes('/public')) {
        type_ticket.value = 'public';
    }

    return {
        type_ticket
    }
}

export function formatdate(date: date) {
    //TODO@gauthier check si c'est replacable par la meme fonction que dans timeclock
    return moment(date).format('DD/MM/YYYY HH:mm');
}

export function getUserData(user_id: number) {
    let user_key = Object.keys(elements.value.users).find(key => {
        let user = elements.value.users[key];
        return typeof user === 'object' && user.id === user_id;
    });

    return elements.value.users[user_key];
}

watch(ticket_select, (newValue, oldValue) => {
    show_loading_animation.value = 'Chargement du ticket en cours...';

    if (newValue != oldValue && newValue > 0) {
        showDetails(newValue).then(response => {
            show_loading_animation.value = '';
        });

        history.pushState({}, null, '?ticket=' + newValue);
    } else if (newValue == 0) {
        history.pushState({}, null, actual_url.value);
        show_loading_animation.value = '';
    }
});

export async function showDetails(ticket_id: number) {
    if (ajax_processing.value[ticket_id]) {
        return;
    }

    if (!ticket_id) {
        Toast.warning('Aucun ID de ticket renseigné...');
    }

    ajax_processing.value[ticket_id] = true;

    try {
        let current_ticket = await AjaxHelper.get('/ticket/' + ticket_id);

        if (current_ticket.isSuccess()) {
            ticket_data.value = current_ticket.getData('extra_data');
        } else {
            Toast.error(current_ticket.getMessage());
            return;
        }

        let order_id = ticket_data.value.order_id;

        const regex_order_number = /\b([1-9]\d{8})\b/;
        let match = ticket_data.value.description.match(regex_order_number);

        if (match) {
            if (!order_id) {
                order_id = match[0];
            }

            let highlighted_order_number = `<a href="https://www.driftshop.fr/old_app_dev.php/2jzgte/commande/${match[0]}" target="_blank">${match[0]}</a>`;

            ticket_data.value.description = ticket_data.value.description.replace(match[0], highlighted_order_number);
        }

        if (order_id) {
            let order_ticket = await AjaxHelper.get('/ticket/order/' + order_id);

            if (order_ticket.isSuccess()) {
                ticket_data.value.order = order_ticket.getData('extra_data');
            }
        }

        let other_tickets = await AjaxHelper.post('/ticket/customer', {'email': ticket_data.value.email});

        if (other_tickets.isSuccess()) {
            let customer_other_tickets = other_tickets.getData('extra_data');
            delete customer_other_tickets['ds_' + ticket_id]; // ignore current ticket_id
            ticket_data.value.other_tickets = customer_other_tickets;
        }

        if (ticket_data.value.product_id > 0) {
            let product_ticket = await AjaxHelper.get('/ticket/product/' + ticket_data.value.product_id);

            if (product_ticket.isSuccess()) {
                ticket_data.value.product = product_ticket.getData('extra_data');
            }
        }

        if (!ticket_data.value.customer || Object.keys(ticket_data.value.customer).length == 0) {
            let customer_ticket: AjaxHelperResponse = await AjaxHelper.post('/ticket/customer-profile', {'email': ticket_data.value.email});

            if (customer_ticket.isSuccess()) {
                ticket_data.value.customer = customer_ticket.getData('extra_data');
            }
        }

        ticket_data.value.status_id = ticket_data.value.status ? ticket_data.value.status.id : 0;
        ticket_data.value.status_color = ticket_data.value.status ? ticket_data.value.status.color : '';
        ticket_data.value.project_id = ticket_data.value.project ? ticket_data.value.project.id : 0;
        ticket_data.value.project_color = ticket_data.value.project ? ticket_data.value.project.color : '';

        show_discussion_form.value = true; // Always visible ticket_data.value.discussions.length > 0;

        discussion_form.value = {
            processing: '',
            status: 0,
            processing_id: 0
        }

        if (ticket_data.value.discussions_processing.length > 0) {
            discussion_form.value.processing = ticket_data.value.discussions_processing[0].message;
            discussion_form.value.status = ticket_data.value.discussions_processing[0].status;
            discussion_form.value.processing_id = ticket_data.value.discussions_processing[0].id;
            show_discussion_form.value = true;
        }

        tickets.value['ds_' + ticket_id] = ticket_data.value;
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_id] = false;
    }
}

export function updateTicket(ticket_id: number): void {
    ticket_select.value = ticket_id;
}

export function hideDetails() {
    updateTicket(0);
}

export function handleShortcut(event: object) {
    let inputs = document.querySelectorAll('input, textarea, trix-editor');
    let isFocus = false;
    let canDoKeyboardShortcut = true;

    inputs.forEach(function (input) {
        if (input === document.activeElement) {
            isFocus = true;
            canDoKeyboardShortcut = false;
        }
    });

    if (open_ticket.value === true) {
        canDoKeyboardShortcut = false;
    }

    let lightboxOpen = false;

    let list_lightboxs = document.querySelectorAll('.lightbox');
    list_lightboxs.forEach(function (lightbox) {
        if (lightbox.offsetHeight > 0) {
            lightboxOpen = true;
        }
    });

    if (lightboxOpen) {
        if (event.key === "Esc" || event.key === "Escape") {
            document.querySelectorAll('.close-lightbox')[0].click();
            history.pushState({}, null, '?ticket=' + ticket_select.value);
        }

        return true;
    }

    // ticket open
    if (ticket_select.value > 0) {
        if (event.key === "Esc" || event.key === "Escape") {
            hideDetails();
        } else if (event.key === "ArrowLeft") {
            if (canDoKeyboardShortcut) {
                showPreviousTicket();
            }
        } else if (event.key === "ArrowRight") {
            if (canDoKeyboardShortcut) {
                showNextTicket();
            }
        } else if (event.key === "!") {
            if (canDoKeyboardShortcut && DsUser.haveRole('tickets.manage')) {
                spam();
            }
        } else if (event.key === "#" || event.key === "Delete") {
            if (canDoKeyboardShortcut && DsUser.haveRole('tickets.manage')) {
                toTrash(ticket_select.value);
            }
        } else if (event.key === "d") {
            if (canDoKeyboardShortcut) {
                //inDuplicate(ticket_select.value);
            }
        } else if (event.key >= 0 && event.key <= 5) {
            if (canDoKeyboardShortcut && DsUser.haveRole('tickets.manage')) {
                changePriority(ticket_select.value, event.key);
            }
        } else if (event.key === "r") {
            if (show_discussion_form.value === false && isFocus === false) {
                show_discussion_form.value = true;
                document.getElementById('discussion-trix-message').focus();
            }
        }
    } else { // list tickets
        if (event.key === " " && document.querySelector('.task') && canDoKeyboardShortcut) {
            document.querySelector('.task').click();
        }
    }
}

function nextTicket(ticket_id: number) {
    let resetArray = Object.keys(tickets.value);

    let ticket_key = resetArray.indexOf('ds_' + ticket_id.toString());
    let next_ticket = 0;

    if (ticket_key >= 0) {
        if (resetArray.length > (ticket_key + 1)) {
            next_ticket = resetArray[ticket_key + 1];
        } else {
            next_ticket = '';
        }
    }

    return next_ticket;
}

function previousTicket(ticket_id: number) {
    let resetArray = Object.keys(tickets.value);

    let ticket_key = resetArray.indexOf('ds_' + ticket_id.toString());
    let previous_ticket = 0;

    if (ticket_key >= 0) {
        if (ticket_key > 0) {
            previous_ticket = resetArray[ticket_key - 1];
        } else {
            previous_ticket = '';
        }
    }

    return previous_ticket;
}

function unsetTicketSelect() {
    updateTicket(0)
}

export async function toTrash(ticket_id: bigint) {
    if (ajax_processing.value[ticket_id]) {
        return;
    }

    ajax_processing.value[ticket_id] = true;

    try {
        let current_ticket = await AjaxHelper.delete('/ticket/' + ticket_id);

        if (current_ticket.isSuccess()) {
            updateTicketDisplay(ticket_id, current_ticket.getData('extra_data'), 'deleted_at');

            Toast.success('Ticket supprimé !');
        } else {
            Toast.warning(current_ticket.getMessage());
        }
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_id] = false;
    }
}

export async function inDuplicate(ticket_id: bigint) {
    if (ajax_processing.value[ticket_id]) {
        return;
    }

    ajax_processing.value[ticket_id] = true;

    try {
        let current_ticket = await AjaxHelper.post('/ticket/' + ticket_id + '/in-duplicate');

        if (current_ticket.isSuccess()) {
            let current_ticket_data = current_ticket.getData('extra_data');

            updateTicketDisplay(ticket_id, current_ticket_data, 'in_duplicate');

            if (current_ticket_data.in_duplicate == 1) {
                Toast.success('Ticket indiqué dupliqué !');
            } else {
                Toast.info('Ticket dé-dupliqué !');
            }
        } else {
            Toast.warning(current_ticket.getMessage());
        }
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_id] = false;
    }
}

export async function restore(ticket_id: bigint) {
    if (ajax_processing.value[ticket_id]) {
        return;
    }

    ajax_processing.value[ticket_id] = true;

    try {
        let current_ticket = await AjaxHelper.post('/ticket/' + ticket_id + '/restore');

        if (current_ticket.isSuccess()) {
            updateTicketDisplay(ticket_id, current_ticket.getData('extra_data'), 'deleted_at');

            Toast.success('Ticket restauré !');
        } else {
            Toast.warning(current_ticket.getMessage());
        }
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_id] = false;
    }
}

export function showPreviousTicket() {
    let previous_ticket = previousTicket(ticket_data.value.id);

    if (previous_ticket != '0') {
        if (tickets.value[previous_ticket]) {
            updateTicket(tickets.value[previous_ticket].id);
        } else {
            loadPreviousPage();
        }
    } else {
        unsetTicketSelect();

        Toast.warning('Aucuns tickets plus récents');
    }
}

export function showNextTicket() {
    let next_ticket = nextTicket(ticket_data.value.id);

    if (next_ticket != '0') {
        if (tickets.value[next_ticket]) {
            updateTicket(tickets.value[next_ticket].id);
        } else {
            loadNextPage();
        }
    } else {
        unsetTicketSelect();

        Toast.warning('Aucuns autres tickets');
    }
}

export async function loadNextPage() {
    if (current_page.value < nb_page.value) {
        show_loading_animation.value = 'Chargement des anciens tickets en cours...';
        let current_route = paginate.value.path.split('/');

        let ajax_route = current_route[current_route.length - 1] ? current_route[current_route.length - 1].replace(/^\//, '') : '';
        ajax_route = ajax_route == '' ? '' : '/' + ajax_route;

        const { type_ticket} = useOnMountTicket();

        let next_tickets = await AjaxHelper.get('/ticket' + ajax_route + '?page=' + (current_page.value + 1) + '&paginate_url=' + actual_url.value + '&internal=' + type_ticket.value);

        if (next_tickets.isSuccess()) {
            tickets.value = next_tickets.getData('extra_data')['tickets'];

            updateTicket(Object.values(tickets.value)[0].id);

            paginate.value = next_tickets.getData('extra_data')['paginate'];
            current_page.value = paginate.value.current_page;
            nb_page.value = paginate.value.last_page;

            Toast.success('Tickets anciens chargés...');
        }
    } else {
        Toast.warning('Aucuns autres tickets');
    }

    show_loading_animation.value = '';
}

export async function loadPreviousPage() {
    if (current_page.value > 1) {
        show_loading_animation.value = 'Chargement des tickets récents en cours...';
        let current_route = paginate.value.paginate_url.split('/tickets');


        let ajax_route = current_route[2] ? current_route[2].replace(/^\//, '') : '';
        ajax_route = ajax_route == '' ? '' : '/' + ajax_route;

        const { type_ticket} = useOnMountTicket();

        let previous_tickets = await AjaxHelper.get('/ticket' + ajax_route + '?page=' + (current_page.value - 1) + '&paginate_url=' + actual_url.value + '&internal=' + type_ticket.value);

        if (previous_tickets.isSuccess()) {
            tickets.value = previous_tickets.getData('extra_data')['tickets'];

            updateTicket(Object.values(tickets.value)[0].id);

            paginate.value = previous_tickets.getData('extra_data')['paginate'];
            current_page.value = paginate.value.current_page;
            nb_page.value = paginate.value.last_page;

            Toast.success('Tickets plus récents chargés...');
        }
    } else {
        Toast.warning('Aucuns tickets plus récents');
    }

    show_loading_animation.value = '';
}

export async function addChat() {
    let params = {
        'message': chat_form.value.message,
        'email': DsUser.getUser('email'),
        'private': 1,
        'source': 'tool',
        'status': 'publish',
        'respond_to': ''
    };

    if (ajax_processing.value[ticket_data.value.id]) {
        return;
    }

    ajax_processing.value[ticket_data.value.id] = true;

    try {
        if (params.message == '') {
            Toast.info('Veuillez indiquer un message dans le chat !');
        } else {
            let current_ticket = await AjaxHelper.post('/ticket/' + ticket_data.value.id + '/discussion', params);

            if (current_ticket.isSuccess()) {
                updateTicketDisplay(ticket_data.value.id, current_ticket.getData('extra_data'));

                chat_form.value.message = '';

                Toast.success('Chat ajouté au ticket !');
            } else {
                Toast.warning(current_ticket.getMessage());
            }
        }
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_data.value.id] = false;
    }
}

export async function spam(ticket_id: bigint) {
    if (ajax_processing.value[ticket_id]) {
        return;
    }

    ajax_processing.value[ticket_id] = true;

    try {
        let current_ticket = await AjaxHelper.put('/ticket/' + ticket_id + '/spam');

        if (current_ticket.isSuccess()) {
            let current_ticket_data = current_ticket.getData('extra_data');

            updateTicketDisplay(ticket_id, current_ticket_data, 'is_spam');

            if (current_ticket_data.is_spam == 1) {
                Toast.success('Ticket marqué comme spam !');
            } else {
                Toast.info('Ticket OK !');
            }
        } else {
            Toast.warning(current_ticket.getMessage());
        }
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_id] = false;
    }
}

export async function subscribeTicket(ticket_id: bigint, user: object) {
    if (ajax_processing.value[ticket_id]) {
        return;
    }

    ajax_processing.value[ticket_id] = true;

    try {
        let user_id = user.id;

        if (user_id) {
            let params: { agent_id: bigint, email: string } = { 'agent_id': user_id, 'email': getUserData(user_id).email };

            let current_ticket = await AjaxHelper.post('/ticket/' + ticket_id + '/subscribe', params);

            if (current_ticket.isSuccess()) {
                let current_ticket_data = current_ticket.getData('extra_data');

                updateTicketDisplay(ticket_id, current_ticket_data, 'subscribers');

                Toast.success(params.email + ' abonné au ticket !');
            } else {
                Toast.warning(current_ticket.getMessage());
            }
        }
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_id] = false;
    }
}

export async function unsubscribeTicket(ticket_id: bigint, email: string) {
    if (ajax_processing.value[ticket_id]) {
        return;
    }

    ajax_processing.value[ticket_id] = true;

    try {
        let params: { email: string } = { 'email': email };

        let current_ticket = await AjaxHelper.delete('/ticket/' + ticket_id + '/subscribe', params);

        if (current_ticket.isSuccess()) {
            updateTicketDisplay(ticket_id, current_ticket.getData('extra_data'), 'subscribers');

            Toast.info(email + ' désabonné du ticket !');
        } else {
            Toast.warning(current_ticket.getMessage());
        }
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_id] = false;
    }
}

export async function resolveTicket(ticket_id: bigint) {
    if (ajax_processing.value[ticket_id]) {
        return;
    }

    ajax_processing.value[ticket_id] = true;

    try {
        let reason_id = event.target.value;

        if (reason_id == 'null') {
            reason_id = null;
        }

        let params = { 'reason_id': reason_id };

        let current_ticket = await AjaxHelper.post('/ticket/' + ticket_id + '/resolve', params);

        if (current_ticket.isSuccess()) {
            updateTicketDisplay(ticket_id, current_ticket.getData(), 'close_at');

            Toast.success('Ticket correctement clôturé !');
        } else {
            Toast.warning(current_ticket.getMessage());
        }
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_id] = false;
    }
}

export async function changePriority(ticket_id: bigint, priority: number) {
    if (
        ajax_processing.value[ticket_id] ||
        (
            ticket_data.value.id == ticket_id &&
            ticket_data.value.priority == priority
        ) ||
        tickets.value['ds_' + ticket_id].priority == priority
    ) {
        return;
    }

    try {
        ajax_processing.value[ticket_id] = true;

        let current_ticket = await AjaxHelper.post('/ticket/' + ticket_id + '/priority', { 'priority': priority });

        if (current_ticket.isSuccess()) {
            updateTicketDisplay(ticket_id, current_ticket.getData('extra_data'), 'priority');

            Toast.success('Priorité mise a jour sur le ticket !');
        } else {
            Toast.warning(current_ticket.getMessage());
        }
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_id] = false;
    }

}

export async function addDiscussion() {
    if (ajax_processing.value[ticket_data.value.id]) {
        return;
    }

    let params = {
        'message': discussion_form.value.message,
        'email': DsUser.getUser('email'),
        'private': 0,
        'source': 'tool',
        'status': discussion_form.value.status,
        'respond_to': discussion_form.value.respond_to,
        'close_ticket_reason_id': ticket_data.value.reason_id
    };

    ajax_processing.value[ticket_data.value.id] = true;

    try {
        if (params.message == '') {
            Toast.info('Veuillez indiquer un message !');
        } else {
            let current_ticket = await AjaxHelper.post('/ticket/' + ticket_data.value.id + '/discussion', params);

            if (current_ticket.isSuccess()) {
                updateTicketDisplay(ticket_data.value.id, current_ticket.getData('extra_data'));
                discussion_form.value.message = '';

                Toast.success('Discussion ajouté au ticket !');
            } else {
                Toast.warning(current_ticket.getMessage());
            }
        }
    } catch (e) {
        Toast.error(e.message);
    } finally {
        ajax_processing.value[ticket_data.value.id] = false;
    }
}

export function respondToDiscussion(discussion_id: bigint) {
    document.querySelector('#discussion-respondto').value = discussion_id;
}

export function nl2br(text: string) {
    if (typeof text === 'undefined' || text === null) {
        return '';
    }

    return (text + '').replace(/(\r\n|\n\r|\r|\n)/g, '<br />' + '$1');
}

export function priorityIcon(ticket_id: bigint, ticket_priority: number) {
    [
        'priority-' + ticket_id,
        'details-priority-' + ticket_id,
        'mobile-priority-' + ticket_id,
    ].forEach(function (element_class) {
        let elements = document.getElementsByClassName(element_class);

        Array.from(elements).forEach(function (element: any, index: number) {
            if (ticket_priority > index) {
                element.classList.remove("fa-regular");
                element.classList.add("fa-solid");
                element.classList.add("text-driftshop");
            } else {
                element.classList.remove("fa-solid");
                element.classList.remove("text-driftshop");
                element.classList.add("fa-regular");
            }
        });
    });
}

export function updateTicketDisplay(ticket_id: bigint, current_ticket: object, field: string) {
    if (field in current_ticket) {
        tickets.value['ds_' + ticket_id][field] = current_ticket[field];

        if (ticket_data.value.id == ticket_id) {
            ticket_data.value[field] = current_ticket[field];
        }
    }
}

export const new_ticket_title: Ref<string> = ref('');
export const new_ticket_description: Ref<string> = ref('');
export const new_ticket_service: Ref<string> = ref('computer');

export async function addTicket() {
    try {
        const new_ticket_data = {
            title: new_ticket_title.value,
            description: new_ticket_description.value,
            service: new_ticket_service.value,
            is_internal: 1,
            project_id: 1,
            status_id: 1,
            source: 'email',
            email: DsUser.getUser('email')
        };

        let current_ticket = await AjaxHelper.post('/ticket', new_ticket_data);

        if (current_ticket.isSuccess()) {
            ticket_data.value = current_ticket.getData('extra_data')['ticket'];
            open_ticket.value = false;

            await showDetails(ticket_data.value.id);

            Toast.success('Ticket crée !');
        } else {
            Toast.warning('Ticket non crée ...');
        }
    } catch (e) {
        Toast.error(e.message);
    }
}
